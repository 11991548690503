body {
    font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}

#sidebar {
    position: fixed;
    z-index: 999;
}
.nav-box{
    box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f;
}
.content-wrapper {
    background: #e9ecef;
    padding: 1.75rem 1.25rem;
    width: 100%;
    flex-grow: 1;
}

ul.nav.left-sidebar-dbmci {
    height: 90vh;
    overflow-y: scroll !important;
}

.container-fluid .main-panel {
    right: 0px;
    position: absolute;
}

a.working_spiner {
    font-size: 18px;
    padding: 0 5px 0 0;
}

.overlay-area .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.75);
    text-align: center;
    vertical-align: middle;
}

.overlay-area {
    position: relative;
}

.overlay-area .overlay i {
    position: absolute;
    top: calc(50% - 15px);
    font-size: 30px;
}

.bottom-box {
    box-shadow: 0px 2px 5px 3px rgb(0 0 0 / 20%);
    -webkit-box-shadow: 0px 2px 5px 3px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.2);
    /* box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 50%), 0px 1px 3px 0px rgb(0 0 0 / 50%); */
}

.card-box,
.mt-card {
    box-shadow: 0px 1px 1px 1px rgb(0 0 0 / 5%);
    -webkit-box-shadow: 0px 1px 1px 1px rgb(0 0 0 / 5%);
    -moz-box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.2);
    /* box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 50%), 0px 1px 3px 0px rgb(0 0 0 / 50%); */
}

.card-body-pd {
    padding: 1.2rem !important;
}

.card.mt-card .card-body {
    padding: 0;
}

.table-sm.mat-table th {
    padding: .8rem 0.3rem !important;
    color: rgba(0, 0, 0, .54);
}

.grid-margin-sm {
    margin-bottom: 0.8rem;
}

.width-max-content {
    width: max-content;
}

.input-group-prepend-dbmci span.input-group-text {
    padding: 0;
    background: unset;
    border: unset;
    border-bottom: 1px solid #15317a;
    color: #0b3ec1;
}

img.header-image-login {
    width: 180px;
    height: 180px;
    border-radius: 50%;
}

.validity-content {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 1fr;
    grid-template-rows: minmax(30px, auto);
    grid-gap: 5px;
    padding: 5px 0 0 0;
}

.form-control {
    /* display: block;
    
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box;
    font-size: 0.8125rem;
    border: none!important;
    padding-left: 5px!important;
    border-radius: 2px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
    width: 100%;
    height: 1.875rem;
    padding: 0.94rem 1.375rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1;
    font: inherit;
    background: transparent;
    color: currentColor;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    vertical-align: bottom;
    text-align: inherit;
    box-sizing: content-box;
    border-bottom: 1px solid #2688e8!important;
}

.form-group label {
    font-weight: 600;
}

select.form-control {
    outline: none;
    padding: 0px;
    color: #000000c7;
    font-size: 15px;
}

*:focus {
    outline: none;
}

.form-control:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

ng-multiselect-dropdown span.dropdown-btn {
    border: none !important;
    border-bottom: 1px solid #2a88e8 !important;
    padding: 3px 12px !important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
    font-size: 14px;
}

.ng-select .ng-select-container {
    color: #333;
    background-color: transparent;
    border-radius: 4px;
    border: none;
    min-height: 36px;
    align-items: center;
    border-bottom: 1px solid #2a88e8;
}

.modal-content {
    outline: 0;
    border: none;
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 21px 0px rgb(0 0 0 / 20%);
    box-shadow: 0px 3px 21px 0px rgb(0 0 0 / 99%);
    background: white;
}

/* .modal.show .modal-dialog {
    max-width: 90%;
} */


/* .form-group {
    margin-bottom: 0.5rem;
}
.was-validated .form-control:invalid, .form-control.is-invalid {
    border-color: #dc3545;
    padding-right: unset;
}
.col-form-label {
    padding-top: 0;
    padding-bottom: 0;
}
.form-group label{
    margin: 0;
} */


/* nav#sidebar {
    position: fixed;
} */


/* .main-panel {
    margin-left: 260px;
} */

::-webkit-scrollbar {
    width: 5px;
}


/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #2d8bfc;
    border-radius: 8px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.btn.btn-fw {
    min-width: 30px;
}

.input-group-append {
    margin-left: -1px;
    margin-top: -2px;
}


/* LOGIN */

.login-container {
    /* width: 30%; */
    border: none;
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 21px 0px rgb(0 0 0 / 20%);
    box-shadow: 0px 3px 21px 0px rgb(0 0 0 / 20%);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    /* for IE 9 */
    -webkit-transform: translate(-50%, -50%);
    /* for Safari */
}

.login-btn-container {
    width: 100%;
}

a.forgot-txt {
    font-size: 14px;
    font-weight: 500;
    color: red;
    font-style: italic;
}

.forgot-container {
    padding: 10px 0;
    text-align: right;
}

form.forgot-form {
    max-width: 350px;
}

.img-header {
    text-align: center;
}

.img-header img {
    width: 100%;
    margin-top: -105px;
}

h1.headind-egurukul {
    font-size: 20px;
    padding: 30px 0;
    font-family: Arial, Helvetica, sans-serif;
}

.d-none{
    display: none;
}
/* END OF THE LOGIN */

.total-content p {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: inherit;
}

.total-content h4.text-value {
    font-size: 35px;
    color: #0a437f;
    font-family: revert;
}

.total-container {
    border-radius: 50%;
    text-align: center;
    box-shadow: 0px 2px 5px 3px rgb(0 0 0 / 20%);
    -webkit-box-shadow: 0px 2px 5px 3px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.2);
    padding: 10px;
}

.profilepicbx img {
    height: 100px;
    width: 100px;
}

.profile-picwrap {
    text-align: center;
}

label.file-upload-lable-dbmci input[type="file"] {
    position: absolute;
    top: -1000px;
}

label.file-upload-lable-dbmci {
    background: linear-gradient(to right, #90caf9, #047edf 99%);
    border: 0;
    transition: opacity 0.3s ease;
    padding: 10px 35px;
    color: white;
    font-size: 14px;
    font-weight: 700;
}

.profilepicbx-ext img {
    width: auto;
    height: 145px;
}

.pull-right {
    float: right;
}

.action_wrap:hover>.action_box {
    display: block;
}

.actoin_option {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
}

.pos_r {
    position: relative;
}

.action_box {
    position: absolute;
    right: 20px;
    padding: 10px;
    text-align: left;
    background: #fff;
    top: -40px;
    z-index: 5;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0px 2px 5px 3px rgb(0 0 0 / 20%);
    -webkit-box-shadow: 0px 2px 5px 3px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.2);
    display: none;
}

.mat-table tbody tr:last-child .action_box {
    top: -45px;
}

ul.action-ul {
    padding: 0;
}

li.action-item {
    text-align: left;
    list-style-type: none;
    cursor: pointer;
    color: #000000cf;
    font-size: 14px;
    padding: 0px;
}

.action-item a {
    color: #000000cf;
}

.action-item label.form-check-label {
    padding-left: 21px;
    width: 100%;
}

.action-item .mdi-delete {
    color: red;
}

.action-item i {
    font-size: 16px;
}

.td-grid-profile {
    display: flex;
}

.form-group-m-0 .form-group {
    margin-bottom: 10px;
}

.img-view-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;
}

.img-view-content {
    border-radius: 5px;
    box-shadow: 0px 2px 5px 3px rgb(0 0 0 / 20%);
    -webkit-box-shadow: 0px 2px 5px 3px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.2);
}

.img-view-content img {
    width: 100%;
    min-height: auto;
    max-height: 200px;
}

img.close-img-content {
    width: 20px;
    height: 20px;
    position: absolute;
    margin: -5px -15px;
}

.d-lock {
    height: 55px;
    width: 100%;
    /* margin-bottom: -1px; */
    position: absolute;
    background: transparent;
    cursor: not-allowed;
}

.grid-limit-paginate {
    display: grid;
    grid-template-columns: 1fr 11fr;
    grid-gap: 10px;
}

.hm-image-container {
    text-align: center;
}

.hm-image-container img {
    max-width: 100%;
    height: 150px;
}

.home-modal-item.card-box.active {
    background: #5dc1ef63;
}

.add-hm-content img {
    width: 100%;
}

.hm-contents {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2px;
}

.hm-contents label {
    font-size: 14px;
    margin: 0;
}

.hm-contents span {
    font-size: 13px;
    margin: 0;
}

.router-link-active span.menu-title {
    color: #308be3 !important;
    font-size: 16px !important;
}

.profile-grid-3 {
    display: grid;
    grid-template-columns: 1fr 2fr 3fr;
    grid-gap: 12px;
}

.profile-grid-3 p.clearfix {
    margin: 0px 0 5px;
}

.table th,
.table td {
    padding: 0.9375rem 0.35rem 0.9375rem 0.35rem;
    /* white-space: inherit; */
}

.th-w-10 {
    width: 10% !important;
}

.th-w-15 {
    width: 15% !important;
}

.th-w-20 {
    width: 20% !important;
}

.react-datepicker__input-container input {
    position: relative;
    display: inline-block;
    width: 100%;
    border: none;
    width: 100%;
    max-width: 100%;
    vertical-align: bottom;
    text-align: inherit;
    box-sizing: content-box;
    border-bottom: 1px solid #2688e8!important;
}

button.accordion-button,
button.accordion-button.collapsed {
    font-size: 14px;
    font-weight: normal;
    width: 100%;
    text-align: inherit;
    border: 1px solid #aab2b9;
    background: #e9ecefa6;
    border-radius: 16px;
    padding: 10px
}

.switch-3d .switch-slider {
    background-color: #d74719;
    border-radius: 50em;
}

.switch-slider {
    position: relative;
    display: block;
    /* height: inherit; */
    cursor: pointer;
    /* background-color: #fff; */
    border: 1px solid #c8ced3;
    transition: 0.15s ease-out;
    height: 28px;
    width: 42px;
    /* border-radius: 0.25rem; */
}

label.switch.switch-3d.switch-primary.grid-switch {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 2px;
}

.grid-switch span {
    font-size: 14px;
    line-height: initial;
}

label.switch.switch-3d.switch-primary.grid-switch {
    margin: 0;
}

.switch-3d .switch-slider::before {
    top: -1px;
    left: -1px;
    width: 28px;
    height: 27px;
    border: 0;
    border-radius: 50em;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.switch-slider::before {
    position: absolute;
    /* top: 2px; */
    /* left: 2px; */
    box-sizing: border-box;
    /* width: 20px; */
    /* height: 20px; */
    content: "";
    background-color: #fff;
    /* border: 1px solid #c8ced3; */
    transition: 0.15s ease-out;
    /* border-radius: 0.125rem; */
}

.switch-input {
    display: none;
}

.switch-primary .switch-input:checked+.switch-slider {
    background-color: #20a8d8;
    border-color: #1985ac;
}

.switch-input:checked~.switch-slider::before {
    /* -webkit-transform: translateX(14px); */
    transform: translateX(14px);
}

.switch-primary .switch-input:checked+.switch-slider::before {
    border-color: #1985ac;
}

label.switch.switch-3d.switch-primary.grid-switch-1 {
    margin: 0 !important;
}

.required:after {
    content:" *";
    color: red;
  }
/* Loader */
.loader-container {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    opacity: 0.7;
    background-color: #e9ecefbd;
    z-index: 99;
}
#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 99999999999;
    width: 100px;
    height: 100px;
    margin: -76px 0 0 -76px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* Add animation to "page content" */

.animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
}

@-webkit-keyframes animatebottom {
    from {
        bottom: -100px;
        opacity: 0
    }
    to {
        bottom: 0px;
        opacity: 1
    }
}

@keyframes animatebottom {
    from {
        bottom: -100px;
        opacity: 0
    }
    to {
        bottom: 0;
        opacity: 1
    }
}


/* End Loader */
.faqs-header-grid {
    display: grid;
    grid-template-columns: 10fr 1fr 1fr;
    grid-template-rows: minmax(30px, auto);
    grid-gap: 5px;
    padding: 5px 0 0 0;
}
.accordion-collapse.collapse.show {
    border-bottom: 1px solid #aab2b9;
    border-left: 1px solid #aab2b9;
    border-right: 1px solid #aab2b9;
    border-radius: 0px 0px 20px 10px;
    padding: 10px;
    margin-top: -8px;
}

button.accordion-button {
    border-bottom: 0px solid;
    border-radius: 10px 10px 0 0;
    padding: 10px;
}
.accordion-body {
    display: grid;
    grid-template-columns: 12fr 0fr;
    grid-gap: 5px;
}
ul.nav.scroll-side-nav {
    height: 90vh;
    overflow-y: scroll !important;
}
.custome-nav a,
.custome-nav a.active {
    padding: 10px 20px;
}
.custome-nav a.active {
    padding: 10px 20px;
    background: #5a429b;
    color:white;
    border-radius: 50px;
    font-weight: 600;
    font-size: 14px;
}
.custome-nav a {
    padding: 10px 20px;
    background: #e9ecef;
    color: #333;
    border-radius: 50px;
    font-weight: 600;
    font-size: 14px;
}
.custome-nav>li.nav-item {
    font-size: 16px;
}
  h3 {
    text-align: center;
    padding: 0 10px;
  }
  
  .tree {
    margin: 18px;
    padding: 0;
  }
  .tree:not(:empty):before, .tree:not(:empty):after,
  .tree ul:not(:empty):before,
  .tree ul:not(:empty):after,
  .tree li:not(:empty):before,
  .tree li:not(:empty):after {
    display: block;
    position: absolute;
    content: "";
  }
  .tree ul,
  .tree li {
    position: relative;
    margin: 0;
    padding: 0;
  }
  .tree li {
    list-style: none;
  }
  .tree li > div {
    background-color: #eee;
    color: #222;
    padding: 5px;
    display: inline-block;
  }
  .tree.cascade li {
    margin-left: 24px;
  }
  .tree.cascade li div {
    margin-top: 12px;
  }
  .tree.cascade li:before {
    border-left: 1px solid #ddd;
    height: 100%;
    width: 0;
    top: 0;
    left: -12px;
  }
  .tree.cascade li:after {
    border-top: 1px solid #ddd;
    width: 12px;
    left: -12px;
    top: 24px;
  }
  .tree.cascade li:last-child:before {
    height: 24px;
    top: 0;
  }
  .tree.cascade > li:first-child:before {
    top: 24px;
  }
  .tree.cascade > li:only-child {
    margin-left: 0;
  }
  .tree.cascade > li:only-child:before, .tree.cascade > li:only-child:after {
    content: none;
  }
  .tree.horizontal li {
    display: flex;
    align-items: center;
    margin-left: 24px;
  }
  .tree.horizontal li div {
    margin: 6px 0;
  }
  .tree.horizontal li:before {
    border-left: 1px solid #ddd;
    height: 100%;
    width: 0;
    top: 0;
    left: -12px;
  }
  .tree.horizontal li:first-child:before {
    height: 50%;
    top: 50%;
  }
  .tree.horizontal li:last-child:before {
    height: 50%;
    bottom: 50%;
    top: auto;
  }
  .tree.horizontal li:after,
  .tree.horizontal li ul:after {
    border-top: 1px solid #ddd;
    height: 0;
    width: 12px;
    top: 50%;
    left: -12px;
  }
  .tree.horizontal li:only-child:before {
    content: none;
  }
  .tree.horizontal li ul:after {
    left: 0;
  }
  .tree.horizontal > li:only-child {
    margin-left: 0;
  }
  .tree.horizontal > li:only-child:before, .tree.horizontal > li:only-child:after {
    content: none;
  }
  .tree.vertical {
    display: flex;
  }
  .tree.vertical ul {
    display: flex;
    justify-content: center;
  }
  .tree.vertical li {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tree.vertical li div {
    margin: 12px 6px;
  }
  .tree.vertical li:before {
    border-left: 1px solid #ddd;
    height: 12px;
    width: 0;
    top: 0;
  }
  .tree.vertical li:after {
    border-top: 1px solid #ddd;
    height: 0;
    width: 100%;
  }
  .tree.vertical li:first-child:after {
    border-top: 1px solid #ddd;
    height: 0;
    width: 50%;
    left: 50%;
  }
  .tree.vertical li:last-child:after {
    border-top: 1px solid #ddd;
    height: 0;
    width: 50%;
    right: 50%;
  }
  .tree.vertical li:only-child:after {
    content: none;
  }
  .tree.vertical li ul:before {
    border-left: 1px solid #ddd;
    height: 12px;
    width: 0;
    top: -12px;
  }
  .tree.vertical > li:only-child:before, .tree.vertical > li:only-child:after {
    content: none;
  }
  .tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li, .tree.vertical.cascade-3 > li > ul > li > ul > li, .tree.vertical.cascade-2 > li > ul > li, .tree.vertical.cascade-1 > li, .tree.vertical .cascade {
    flex-direction: column;
    align-items: start;
    padding: 0 12px;
  }
  .tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li:before, .tree.vertical.cascade-3 > li > ul > li > ul > li:before, .tree.vertical.cascade-2 > li > ul > li:before, .tree.vertical.cascade-1 > li:before, .tree.vertical .cascade:before {
    left: 24px;
  }
  .tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li:after, .tree.vertical.cascade-3 > li > ul > li > ul > li:after, .tree.vertical.cascade-2 > li > ul > li:after, .tree.vertical.cascade-1 > li:after, .tree.vertical .cascade:after {
    left: 0;
  }
  .tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li:first-child:after, .tree.vertical.cascade-3 > li > ul > li > ul > li:first-child:after, .tree.vertical.cascade-2 > li > ul > li:first-child:after, .tree.vertical.cascade-1 > li:first-child:after, .tree.vertical .cascade:first-child:after {
    left: 24px;
    width: 100%;
  }
  .tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li:last-child:after, .tree.vertical.cascade-3 > li > ul > li > ul > li:last-child:after, .tree.vertical.cascade-2 > li > ul > li:last-child:after, .tree.vertical.cascade-1 > li:last-child:after, .tree.vertical .cascade:last-child:after {
    left: 0;
    width: 24px;
  }
  .tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li ul, .tree.vertical.cascade-3 > li > ul > li > ul > li ul, .tree.vertical.cascade-2 > li > ul > li ul, .tree.vertical.cascade-1 > li ul, .tree.vertical .cascade ul,
  .tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li li,
  .tree.vertical.cascade-3 > li > ul > li > ul > li li,
  .tree.vertical.cascade-2 > li > ul > li li,
  .tree.vertical.cascade-1 > li li,
  .tree.vertical .cascade li {
    display: block;
  }
  .tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li ul:before, .tree.vertical.cascade-3 > li > ul > li > ul > li ul:before, .tree.vertical.cascade-2 > li > ul > li ul:before, .tree.vertical.cascade-1 > li ul:before, .tree.vertical .cascade ul:before,
  .tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li ul:after,
  .tree.vertical.cascade-3 > li > ul > li > ul > li ul:after,
  .tree.vertical.cascade-2 > li > ul > li ul:after,
  .tree.vertical.cascade-1 > li ul:after,
  .tree.vertical .cascade ul:after,
  .tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li li:before,
  .tree.vertical.cascade-3 > li > ul > li > ul > li li:before,
  .tree.vertical.cascade-2 > li > ul > li li:before,
  .tree.vertical.cascade-1 > li li:before,
  .tree.vertical .cascade li:before,
  .tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li li:after,
  .tree.vertical.cascade-3 > li > ul > li > ul > li li:after,
  .tree.vertical.cascade-2 > li > ul > li li:after,
  .tree.vertical.cascade-1 > li li:after,
  .tree.vertical .cascade li:after {
    border: none;
  }
  .tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li div, .tree.vertical.cascade-3 > li > ul > li > ul > li div, .tree.vertical.cascade-2 > li > ul > li div, .tree.vertical.cascade-1 > li div, .tree.vertical .cascade div {
    margin: 0;
    margin-top: 12px;
  }
  .tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li li, .tree.vertical.cascade-3 > li > ul > li > ul > li li, .tree.vertical.cascade-2 > li > ul > li li, .tree.vertical.cascade-1 > li li, .tree.vertical .cascade li {
    margin-left: 24px;
  }
  .tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li li:before, .tree.vertical.cascade-3 > li > ul > li > ul > li li:before, .tree.vertical.cascade-2 > li > ul > li li:before, .tree.vertical.cascade-1 > li li:before, .tree.vertical .cascade li:before {
    border-left: 1px solid #ddd;
    height: 100%;
    width: 0;
    top: 0;
    left: -12px;
  }
  .tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li li:after, .tree.vertical.cascade-3 > li > ul > li > ul > li li:after, .tree.vertical.cascade-2 > li > ul > li li:after, .tree.vertical.cascade-1 > li li:after, .tree.vertical .cascade li:after {
    border-top: 1px solid #ddd;
    width: 12px;
    height: 0;
    left: -12px;
    top: 24px;
    content: "";
  }
  .tree.vertical.cascade-4 > li > ul > li > ul > li > ul > li li:last-child:before, .tree.vertical.cascade-3 > li > ul > li > ul > li li:last-child:before, .tree.vertical.cascade-2 > li > ul > li li:last-child:before, .tree.vertical.cascade-1 > li li:last-child:before, .tree.vertical .cascade li:last-child:before {
    height: 24px;
    top: 0;
  }
  .category-list .category-action {
    margin: 0 !important;
    padding: 0;
    /* font-size: 16px; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}
.category-action a {
    line-height: initial;
}
.category-list h5.category-title {
    font-size: 14px;
    font-weight: 500;
}
select.form-control-append {
    border: 0;
    background: transparent;
    border-bottom: 1px solid #2688e8;
}
.form-group.col-sm-4.readonly {
    background: white;
    pointer-events: none;
}
.readonly .form-control {
    background: #e9ecef96;
}
.readonly select{
    background: #e9ecef;
    pointer-events: none;
}
/* ------------------Nav */
img.nav-link-img {
    width: 25px;
    margin-right: 8px;
}
.sidebar .nav .nav-item .nav-link {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 10px 0 10px 0;
    color: #3e4b5b;
    transition-duration: 0.45s;
    transition-property: color;
    -webkit-transition-property: color;
}
.sidebar .nav .nav-item .nav-link .menu-title {
    color: inherit;
    display: inline-block;
    font-size: 0.91rem;
    line-height: 1;
    vertical-align: middle;
}
.sidebar .nav .nav-item {
    padding: 0 1.25rem;
    transition-duration: 0.25s;
    transition-property: background;
    -webkit-transition-property: background;
}
.sidebar .nav .nav-item.active {
    background: #a26dfc;
    color: white;
    border-radius: 0 30px 30px 0;
    /* border-left: 5px solid #5e469d; */
}
.sidebar .nav .nav-item.active > .nav-link .menu-title {
    color: #ffffff;
}
.sidebar .nav .collapse-nav-item.active {
    background: white !important;
}
.sidebar .nav .collapse-nav-item.active > .nav-link .menu-title {
    color: #5a429b;
}
/* END NAV */
span.form-control {
    height: auto;
    min-height: 1.875rem;
}
/* ----------------Ratings--------------- */
.br-widget a:after {
    content: "\2605";
    color: #d2d2d2;
}
.br-widget a.br-selected:after {
    color: #5a429b;
}
.font-size-25{
    font-size: 25px;
}
.rating-bar .progress {
    width: 100%;
    vertical-align: bottom;
    margin-top: 10px;
}
.rating-bar {
    display: flex;
}
.pointer-none{
    pointer-events: none;
}
.rating-customer {
    text-align: center;
    padding: 10%;
    color: #b1b1b1;
    font-size: 30px;
}
.table td, .table th {
    white-space: inherit !important;
}
th.th-1 { width: 1%;}
th.th-2 { width: 2%;}
th.th-3 { width: 3%;}
th.th-4 { width: 4%;}
th.th-5 { width: 5%;}
th.th-6 { width: 6%;}
th.th-7 { width: 7%;}
th.th-8 { width: 8%;}
th.th-9 { width: 9%;}
th.th-10 { width: 10%;}
th.th-11 { width: 11%;}
th.th-12 { width: 12%;}
th.th-13 { width: 13%;}
th.th-14 { width: 14%;}
th.th-15 { width: 15%;}
th.th-16 { width: 16%;}
th.th-17 { width: 17%;}
th.th-18 { width: 18%;}
th.th-19 { width: 19%;}
th.th-20 { width: 20%;}
th.th-21 { width: 21%;}
th.th-22 { width: 22%;}
th.th-23 { width: 23%;}
th.th-24 { width: 24%;}
th.th-25 { width: 25%;}
th.th-26 { width: 26%;}
th.th-27 { width: 27%;}
th.th-28 { width: 28%;}
th.th-29 { width: 29%;}
th.th-30 { width: 30%;}
th.th-31 { width: 31%;}
th.th-32 { width: 32%;}
th.th-33 { width: 33%;}
th.th-34 { width: 34%;}
th.th-35 { width: 35%;}
th.th-36 { width: 36%;}
th.th-37 { width: 37%;}
th.th-38 { width: 38%;}
th.th-39 { width: 39%;}
th.th-40 { width: 40%;}
th.th-41 { width: 41%;}
th.th-42 { width: 42%;}
th.th-43 { width: 43%;}
th.th-44 { width: 44%;}
th.th-45 { width: 45%;}
th.th-46 { width: 46%;}
th.th-47 { width: 47%;}
th.th-48 { width: 48%;}
th.th-49 { width: 49%;}
th.th-50 { width: 50%;}
th.th-51 { width: 51%;}
th.th-52 { width: 52%;}
th.th-53 { width: 53%;}
th.th-54 { width: 54%;}
th.th-55 { width: 55%;}
th.th-56 { width: 56%;}
th.th-57 { width: 57%;}
th.th-58 { width: 58%;}
th.th-59 { width: 59%;}
th.th-60 { width: 60%;}
th.th-61 { width: 61%;}
th.th-62 { width: 62%;}
th.th-63 { width: 63%;}
th.th-64 { width: 64%;}
th.th-65 { width: 65%;}
th.th-66 { width: 66%;}
th.th-67 { width: 67%;}
th.th-68 { width: 68%;}
th.th-69 { width: 69%;}
th.th-70 { width: 70%;}
th.th-71 { width: 71%;}
th.th-72 { width: 72%;}
th.th-73 { width: 73%;}
th.th-74 { width: 74%;}
th.th-75 { width: 75%;}
th.th-76 { width: 76%;}
th.th-77 { width: 77%;}
th.th-78 { width: 78%;}
th.th-79 { width: 79%;}
th.th-80 { width: 80%;}
th.th-81 { width: 81%;}
th.th-82 { width: 82%;}
th.th-83 { width: 83%;}
th.th-84 { width: 84%;}
th.th-85 { width: 85%;}
th.th-86 { width: 86%;}
th.th-87 { width: 87%;}
th.th-88 { width: 88%;}
th.th-89 { width: 89%;}
th.th-90 { width: 90%;}
th.th-91 { width: 91%;}
th.th-92 { width: 92%;}
th.th-93 { width: 93%;}
th.th-94 { width: 94%;}
th.th-95 { width: 95%;}
th.th-96 { width: 96%;}
th.th-97 { width: 97%;}
th.th-98 { width: 98%;}
th.th-99 { width: 99%;}
th.th-100 { width: 100%;}
.sidebar .nav.sub-menu .nav-item .nav-link:before{
    content: "";
}
.sidebar .nav.sub-menu .nav-item .nav-link {
    color: #888;
    padding: 0.75rem 2rem 0.75rem 1rem;
}
.sub-menu img.nav-link-img {
    width: 20px;
    margin-right: 8px;
  }
  .sidebar .nav.sub-menu .nav-item:hover {
    background: #bb9fea; }
.m-r-10{
    margin-right: 10px;
}
.upload-form-container {
    height: 100px;
    position: relative;
    text-align: center;
}

.upload-form-containt {
    margin-top: 50px;
}
.form-error .form-control {
    border-bottom: 1px solid red !important;
}
@media only screen and (min-width: 640px) {
    .login-container{
        width: 90%;
    }
}
@media only screen and  (max-width: 768px) {
    .login-container{
        width: 90%;
    }
    .card.p-4 {
        padding: 10px !important;
    }
    .card-body-pd {
        padding: 0.2rem !important;
    }
}
@media only screen and  (min-width: 992px) {
    .login-container{
        width: 90%;
    }
}
@media only screen and (min-width: 1200px) {
    .login-container{
        width: 35%;
    }
}
.position_relative_inr span.inr_a {
    padding: 7px 3px 0px 3px;
    border-bottom: 1px solid #2a88e8;
    font-weight: 700;
}

.position_relative_inr {
    display: flex;
    justify-content: space-between;
    text-align: left;
}
.form-input-select {
    display: flex;
    justify-content: space-between;
    text-align: center;
}
.promotion-caontainer {
    margin: 11px 0 11px;
}
.promotion-continer {
    margin: 11px 0 11px;
}
.promotion-img-view img {
    width: 100%;
    max-height: 150px;
    height: auto;
    margin-bottom: 10px;
}
.details-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: minmax(30px, auto);
    grid-gap: 10px;
    padding: 5px 0 0 0;
}
.tracking-wrapper,.details-grid-content {
    background: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f;
}
.details-list-wrapper ul {
    padding: 0;
    text-align: left;
    list-style: none;
    margin-bottom: 0;
}

label.title-label {
font-weight: 600;
font-size: 14px;
}
ul.details-flex-column li {
    display: grid;
    grid-template-columns: 2fr 10fr;
}
.tracking-wrapper {
    margin-top: 10px;
}
ol.progtrckr {
    /* margin: 0; */
    padding: 0;
    list-style-type: none;
}

ol.progtrckr li {
    display: inline-block;
    text-align: center;
    line-height: 3.5em;
}

ol.progtrckr[data-progtrckr-steps="2"] li { width: 49%; }
ol.progtrckr[data-progtrckr-steps="3"] li { width: 33%; }
ol.progtrckr[data-progtrckr-steps="4"] li { width: 24%; }
ol.progtrckr[data-progtrckr-steps="5"] li { width: 19%; }
ol.progtrckr[data-progtrckr-steps="6"] li { width: 16%; }
ol.progtrckr[data-progtrckr-steps="7"] li { width: 14%; }
ol.progtrckr[data-progtrckr-steps="8"] li { width: 12%; }
ol.progtrckr[data-progtrckr-steps="9"] li { width: 11%; }

ol.progtrckr li.progtrckr-done {
    color: black;
    border-bottom: 4px solid yellowgreen;
}
ol.progtrckr li.progtrckr-todo {
    color: silver; 
    border-bottom: 4px solid silver;
}

ol.progtrckr li:after {
    content: "\00a0\00a0";
}
ol.progtrckr li:before {
    position: relative;
    bottom: -2.5em;
    float: left;
    left: 50%;
    line-height: 1em;
}
ol.progtrckr li.progtrckr-done:before {
    content: "\2713";
    color: white;
    background-color: yellowgreen;
    height: 2.2em;
    width: 2.2em;
    line-height: 2.2em;
    border: none;
    border-radius: 2.2em;
}
ol.progtrckr li.progtrckr-todo:before {
    content: "\039F";
    color: silver;
    background-color: white;
    font-size: 2.2em;
    bottom: -1.2em;
}
/** Start loader*/
#dots2 {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50px;
    width: 50px;
    margin: -25px 0 0 -25px;
  }
  
  #dots2 span {
    position: absolute;
    width: 10px;
    height: 10px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    -webkit-animation: dots2 1s infinite ease-in-out;
            animation: dots2 1s infinite ease-in-out;
  }
  
  #dots2 span:nth-child(1) {
    left: 0px;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  
  #dots2 span:nth-child(2) {
    left: 15px;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }
  
  #dots2 span:nth-child(3) {
    left: 30px;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }
  
  #dots2 span:nth-child(4) {
    left: 45px;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }
  
  @keyframes dots2 {
    0% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
      background: #000000;
    }
    50% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
      background: #000000;
    }
    100% {
      -webkit-transform: translateX(7px);
              transform: translateX(7px);
      -webkit-transform: translateX(7px);
              transform: translateX(7px);
      background: #000000;
    }
  }
  @-webkit-keyframes dots2 {
    0% {
      -webkit-transform: translateX(5px);
              transform: translateX(5px);
      background: #000000;
    }
    50% {
      -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
      background: #000000;
    }
    100% {
      -webkit-transform: translateX(7px);
              transform: translateX(7px);
      background: #000000;
    }
  }
  /** End loader*/
  .seller-product-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
}
.seller-product-content {
    border: 1px solid #f8f9fa;
    border-radius: 5px;
    box-shadow: 0px 2px 5px 3px rgb(0 0 0 / 20%);
    -webkit-box-shadow: 0px 2px 5px 3px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.2);
    padding: 10px;
}
.heading-slr {
    display: flex;
    justify-content: space-between;
}
.heading-slr h2 {
    font-size: inherit;
    margin: 0;
}